<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex>
        <v-layout wrap justify-center>
          <v-flex xs11 text-center pt-7 pb-2>
            <span style="font-size: 23px; font-weight: bold"
              >Application form for online reservation of Nature camp</span
            >
          </v-flex>

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <template>
                <div>
                  <v-layout wrap>
                    <v-flex
                      pl-4
                      pt-2
                      pb-2
                      text-left
                      style="background-color: black"
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs4 pt-2 align-self-center>
                          <span style="font-size: 20px; color: white">
                            Add Member Details </span
                          >&nbsp;
                          <v-btn small color="#68D389" @click="addValues()">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start style="background-color: white">
                    <v-card flat>
                      <v-layout wrap>
                        <v-flex
                          pt-1
                          v-for="(item, i) in memberdetails"
                          :key="i"
                        >
                          <v-layout wrap pt-2>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.membername"
                                dense
                                outlined
                                label="membername"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Member Name<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.dob"
                                dense
                                outlined
                                label="dob"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    DOB<span style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="gender"
                                v-model="item.genders"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Gender<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex>
                            <v-flex lg2>
                              <v-btn
                                dark
                                style="background-color: #68d389"
                                dense
                                @click="removeVehicle(i)"
                              >
                                Remove
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-layout>
                </div>
              </template>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-10>
        <v-layout wrap justify-end>
          <v-flex xs2 pr-4>
            <v-btn
              @click="add()"
              color="black"
              block
              type="submit"
              :disabled="!isChecked"
            >
              <span style="color: white">SAVE CHANGES</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  components: {},
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      pdf1: null,
      pdf2: null,
      pdf3: null,
      pdf4: null,
      gender: ["Male", "Female", "Transgender"],
      memberdetails: [],
      leaderName: null,
      genders: ["Male", "Female", "Transgender"],
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      idCardType: null,
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      instHeadName: null,
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      idcardtypes: ["Election Id Card", "Driving License", "Aadhaar Card"],
      instName: null,
      instCode: null,
      instType: null,
      instAddress: null,
      instTeleNo: null,
      instMobileNo: null,
      instEmail: null,
      institutionTypes: ["Government", "Aided", "Private", "Others"],
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      appLoading: false,
      msg: "",
      expandSection: false,
      name: null,
      barlist: [],

      male: null,
      memberCount: null,
      leaderdetails: [],

      applicantCategory: "",
      applicantname: null,
      applicantcategorys: [
        "Students",
        "Press Reporters",
        "LSG Members",
        "NGO youth/charitable organization",
      ],
    };
  },
  watch: {
    startDate() {
      this.checkAvailability();
    },
  },
  computed: {
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
  },
  mounted() {
    this.checkAvailability();
  },

  methods: {
    handleFileChange(field) {
      this[field] = this.$refs[field].files[0];
    },
    validateFile(file) {
      return file.size <= 100 * 1024;
    },
    addvehi() {
      console.log("sasasa", this.leaderdetails);
    },
    addLeaderDetails() {
      this.leaderdetails.push({
        leaderName: "",
        leaderGender: "",
        leaderAddress1: "",
        leaderAddress2: "",
        telephone: "",
        leaderPhone: "",
        leaderEmail: "",
      });
      this.leaderGender = "";
      this.leaderName = "";
      this.leaderAddress1 = "";
      this.leaderAddress2 = "";
      this.telephone = "";
      this.leaderPhone = "";
      this.leaderEmail = "";
    },
    removeValues() {
      this.leaderdetails.pop();
    },
    removeleaderdetails(index) {
      this.leaderdetails.splice(index, 1);
    },

    addValues() {
      this.memberdetails.push({ genders: "", dob: "", membername: "" });
    },
    removeVehicle(index) {
      this.memberdetails.splice(index, 1);
    },
    add() {
      if (this.pdf1 && !this.validateFile(this.pdf1)) {
        alert("PDF 1 file size exceeds 100 KB. Please select a smaller file.");
        return;
      }
      if (this.pdf2 && !this.validateFile(this.pdf2)) {
        alert("PDF 2 file size exceeds 100 KB. Please select a smaller file.");
        return;
      }
      if (this.pdf3 && !this.validateFile(this.pdf3)) {
        alert("PDF 3 file size exceeds 100 KB. Please select a smaller file.");
        return;
      }
      if (this.pdf4 && !this.validateFile(this.pdf4)) {
        alert("PDF 4 file size exceeds 100 KB. Please select a smaller file.");
        return;
      }

      const formData = new FormData();
      formData.append("pdf1", this.pdf1);
      formData.append("pdf2", this.pdf2);
      formData.append("pdf3", this.pdf3);
      formData.append("pdf4", this.pdf4);
      formData.append("leaderdetails", JSON.stringify(this.leaderdetails));
      formData.append("memberdetails", JSON.stringify(this.memberdetails));

      formData.append("leaderName", this.leaderName);
      formData.append("leaderAddress1", this.leaderAddress1);
      formData.append("leaderAddress2", this.leaderAddress2);
      formData.append("telephone", this.telephone);
      formData.append("leaderPhone", this.leaderPhone);
      formData.append("leaderGender", this.leaderGender);
      formData.append("leaderEmail", this.leaderEmail);
      formData.append("instHeadName", this.instHeadName);
      formData.append("headDesignation", this.headDesignation);
      formData.append("idCardType", this.idCardType);
      formData.append("idNo", this.idNo);
      formData.append("campid", this.$route.query.id);
      formData.append("instName", this.instName);
      formData.append("instCode", this.instCode);
      formData.append("instType", this.instType);
      formData.append("instAddress", this.instAddress);
      formData.append("instTeleNo", this.instTeleNo);
      formData.append("instMobileNo", this.instMobileNo);
      formData.append("instEmail", this.instEmail);
      formData.append("startDate", this.startDate);
      formData.append("isChecked", this.isChecked);
      formData.append("expandSection", this.expandSection);
      formData.append("name", this.name);
      formData.append("selectedDistrict", this.selectedDistrict);
      formData.append("district", this.district);
      formData.append("campDuration", this.campDuration);
      formData.append("menu1", this.menu1);
      formData.append("male", this.male);
      formData.append("memberCount", this.memberCount);
      formData.append("applicantCategory", this.applicantCategory);
      formData.append("applicantname", this.applicantname);

      axios({
        method: "POST",
        url: "/apply/camxxp",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.emitCampDetailsUpdated();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    checkAvailability() {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: this.startDate,
          campid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.natureCampDetails;
            this.emitCampDetailsUpdated();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>